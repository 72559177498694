import { memo} from 'react';
import PropTypes from 'prop-types';
import { makeSimpleText } from 'utils/render';
import { useSanaTexts } from 'components/sanaText';
import { getFormatPrice } from 'utils/format';
import { useCurrencyInfo } from 'utils/hooks';

const ProductRetailPrice = ({ retailPrice }) => {
  const currencyInfo = useCurrencyInfo();
  const formatAsPrice = currencyInfo && getFormatPrice(currencyInfo);
  const formatedRecomendPrice = formatAsPrice(retailPrice);

  const { texts: [
    retailPriceLbl,
  ] } = useSanaTexts([
    'RetailPrice',
  ]);

  return (
    <>
      <span className="recomend-price">
        {makeSimpleText(retailPriceLbl, [formatedRecomendPrice])}
       </span>
    </>
  );
};

ProductRetailPrice.propTypes = {
  retailPrice: PropTypes.number,
};

export default memo(props => {  
  return <ProductRetailPrice {...props} />;
});

function formatPrice(price, currencyInfo) {
  if (currencyInfo.decimalDigits === 0)
    return price;

  return price.replace(/(\d+)([^\d]*)$/, '<sup>$1</sup>$2');
}
