import { memo } from 'react';
import PropTypes from 'prop-types';
import { useSanaTexts, SimpleText } from 'components/sanaText';
import { makeRichText, makeSimpleText } from 'utils/render';

const UomTitle = ({ id, description, allowHtmlTags = true, children: renderTitle, placeholder = null, isfromUomSelector = true }) => {
  const options = [createUomTextKey(id)];
  options.disableInsiteEditor = true;

  const { texts, loaded } = useSanaTexts(options);

  if (!loaded)
    return placeholder;

  const result = (allowHtmlTags ? makeRichText : makeSimpleText)(texts[0] || description || id);
  //180205 - Phase 2 - 3.17 Product � Virtual UOM �boxes�

  debugger;
  if (!isfromUomSelector) {
    return renderTitle
      ? renderTitle(result)
      : result;
  }
  else {
    return (
      <div>
        <SimpleText textKey="FixedUnitOfMeasureBox" />
      </div>
    )
  }
};

UomTitle.propTypes = {
  id: PropTypes.string,
  description: PropTypes.string,
  allowHtmlTags: PropTypes.bool,
  children: PropTypes.func,
  placeholder: PropTypes.node,
  isfromUomSelector: PropTypes.bool,
};

export const createUomTextKey = id => `UnitOfMeasure_${id || 'Empty'}`;

export default memo(UomTitle);
